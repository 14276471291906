<template>
    <div class="release">
        <div class="top">
            <img :src="topImg" alt="">
        </div>
        <div class="main">
            
            <div class="content">
                <div v-if="changeContent == 1">
                    <div class="title">
                        <h2>产品发布</h2>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="release-imgs">
            <!-- 占位 -->
            <img src="../../assets/img/release/1.png"  />
            <img src="../../assets/img/release/2.png"  />
        </div>
        <says/>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import foots from '@/components/pc/foot'
import says from '@/components/pc/say'
import hotline from '@/components/pc/hotline.vue'
export default {
    components:{
        foots,
        says,
        hotline
    },
    data(){
        return{
            topImg:require('@/assets/img/pcImg/aboutImg/12.png'),
            leftnavIcon1:require('@/assets/img/pcImg/aboutImg/about-sidebar-helo.png'),
            leftnavIcon2:require('@/assets/img/pcImg/aboutImg/about-sidebar-icon-contact.png'),
            questionIcon1:require('@/assets/img/pcImg/aboutImg/about-icon-content.png'),
            questionIcon2:require('@/assets/img/pcImg/aboutImg/about-icon-question.png'),
            changeContent:1,
        }
    }
}
</script>

<style lang="less" scoped>
.release{
    .top{
        width: 100%;
        height: 460px;
        background-image: repeating-linear-gradient(to right , rgb(31, 104, 240),  rgb(0, 79, 224));
        img{
            width: 100%;
            height: 460px;
            opacity: 0.2;
            z-index: -5;
        }
    }
    .main{
        width: 1200px;
        // height: 1074px;
        // background-color: darkslategrey;
        position: absolute;
        top: 150px;
        left: 50%;
        margin-left: -600px;
        .leftnav{
            width: 287px;
            height: 552px;
            background: #FFFFFF;
            box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
            opacity: 1;
            border-radius: 6px;
            .leftnav-head{
                width: 287px;
                height: 70px;
                background: #FAFBFE;
                border-radius: 3px 3px 0px 0px;
                img{
                    margin: 15px 10px 15px 20px;
                    vertical-align:middle;
                }
                span{
                    font-size: 16px;
                    font-weight: bold;
                    color: #31384E;
                    opacity: 1;
                }
            }
            .leftnav-title{
                width: 217px;
                height: 40px;
                // background: #EFF6FF;
                opacity: 1;
                border-radius: 25px 0px 0px 25px;
                margin: 10px 0 0 30px;
                font-size: 14px;
                font-weight: bold;
                line-height: 40px;
                color: #31384E;
                -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
                padding-left: 40px;
                cursor:pointer;
            }
            .changecontent{
                color: #207BFA;
                background: #EFF6FF;
            }
            .leftnav-content1{
                font-size: 14px;
                font-weight: 400;
                color: #31384E;
                margin: 20px 36px 20px 51px;
                span{
                    font-size: 14px;
                    font-weight: bold;
                    color: #207BFA;
                    cursor:pointer;
                }
            }
            .leftnav-content2{
                width: 207px;
                height: 69px;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #207BFA;
                margin: 20px 29px 20px 51px;
            }
        }
        .content{
            width: 800px;
            position: absolute;
            top: 70px;
            left: 400px;
            // background-color: dimgray;
            .title{
                font-weight: bold;
                color: #FFFFFF;
                // background-color: dodgerblue;
                height: 240px;
                h2{
                    font-size: 28px;
                    margin-bottom: 50px;
                }
                p{
                    font-size: 18px;
                    margin-bottom: 25px;
                }

            }
            .profile{
                font-size: 14px;
                font-weight: 400;
                color: #697598;
                margin-bottom: 40px;
                p{
                    margin-top: 20px;
                    text-indent:2em;
                }
            }
            .question-title{
                // background-color: dodgerblue;
                margin-top: 28px;
                img{
                    vertical-align:middle;
                    margin: -8px 10px 0 0 ;
                }
                span{
                    font-size: 18px;
                    font-weight: bold;
                    color: #31384E;
                }
            }
            .question{
                width: 800px;
                height: 40px;
                background: #FAFBFE;
                border-radius: 10px;
                line-height: 40px;
                text-indent:2em;
                margin: 20px 0 20px 0 ;
                font-size: 14px;
                font-weight: bold;
                color: #31384E;
            }
            .answer{
                width: 760px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #697598;
                margin-left: 40px;
            }
            .learnmore{
                font-size: 14px;
                font-weight: bold;
                color: #31384E;
                margin: 60px 0 60px 40px;
                span{
                    color:#207BFA;
                    a {
                        text-decoration: none;
                        color:#207BFA;
                    }
                    
                }
            }
        }
        
    }
    .release-imgs{
        text-align: center;
    }
    .occupy1{
        width: 100%;
        height: 764px;
        // background-color: dodgerblue;
        z-index: -5;
    }
    .occupy2{
        width: 100%;
        height: 669px;
        // background-color: dodgerblue;
        z-index: -5;
    }
    /deep/ .foot{
    background-color: #fff;
}
}



</style>