<template>
    <div class="release">
        <div class="top">
            <img :src="topImg" alt="">
            <div class="topstr">
                <h2>产品发布</h2>
                
            </div>
        </div>
        <div class="question">
            <img src="../../assets/img/release/1.png"  />
            <img src="../../assets/img/release/2.png"  />
        </div>
        
        <says/>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import foots from '@/components/mobile/foot'
import says from '@/components/mobile/say'
import hotline from '@/components/mobile/hotline.vue'
export default {
    components:{
        foots,
        says,
        hotline
    },
    data(){
        return{
            topImg:require('@/assets/img/mobileImg/productImg/bg1.png'),
            questionIcon1:require('@/assets/img/pcImg/aboutImg/about-icon-content.png'),
            questionIcon2:require('@/assets/img/pcImg/aboutImg/about-icon-question.png'),
            Icon1:require('@/assets/img/pcImg/aboutImg/about-sidebar-helo.png'),
            Icon2:require('@/assets/img/pcImg/aboutImg/about-sidebar-icon-contact.png'),
            showMore:false,
        }
    }
}
</script>

<style lang="less" scoped>
.release{
    width: 23.4375rem;
    .top{
        height: 25rem;
        width: 23.4375rem;
        color: #FFFFFF;
        img{
            width: 100%;
            position: relative;
            z-index: -5;
        }
        .topstr{
            width: 20.625rem;
            margin: -21rem auto 0 ;
            h2{
                font-size: 1.75rem;
                font-weight: bold;
                text-align: center;
            }
            h3{
                font-size: 0.875rem;
                font-weight: 400;
                margin: 2.375rem 0 0.625rem 0;
            }
            p{
                font-size: 1.125rem;
                font-weight: bold;
            }
        }
        
    }
    .question{
        margin: -12.5rem 0 0 0;
        width: 23.4375rem;
        height: 63.75rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.9375rem 2.6875rem rgba(0, 0, 0, 0.05);
        // background-color: rosybrown;
        border-radius: 0.875rem;
        padding-bottom: 10px;
        img{
            width: 100%;
        }
        
        
    }
    
    /deep/ .foot{
    background-color: #fff;
    }
}
</style>