<template>
    <div class="about">
        <div class="top">
            <img :src="topImg" alt="">
        </div>
        <div class="main">
            <div class="leftnav">
                <div class="leftnav-head">
                    <img :src="leftnavIcon1" alt="">
                    <span>获得帮助</span>
                </div>
                <div class="leftnav-title" 
                    :class="{changecontent:changeContent == 1}"
                    @click="changeContent = 1">
                    关于我们
                </div>
                <div class="leftnav-title"
                    :class="{changecontent:changeContent == 2}"
                    @click="changeContent = 2">
                    常见问题
                </div>
                <div class="leftnav-content1">
                    <p>#1-软件配置要求</p><br>
                    <p>
                        在企业配备医键通软件系统时<br>
                        需要什么样的硬件支持？<br>
                        企业需要额外采购什么样的设备<br>
                        研发周期是多久？是否需要<br>
                        在本地布置服务器？
                    </p><br>
                    <span @click="changeContent = 2">了解更多</span>
                </div>
                <div class="leftnav-head">
                    <img :src="leftnavIcon2" alt="">
                    <span>需要咨询</span>
                </div>
                <div class="leftnav-content2">
                    <p>
                        公司电话：400-992-3006<br>
                        地址：广州市天河区林和西路155号中汇广场公寓楼3006房
                    </p>
                </div>
            </div>
            <div class="content">
                <div v-if="changeContent == 1">
                    <div class="title">
                        <h2>关于我们</h2>
                        <p>广州医键通软件开发有限公司旗下网站（医键通）</p>
                        <p>精于设计+技术创新，提供精致后勤软件服务的医键通</p>
                    </div>
                    <div class="profile">
                        <p>创办医键通之前，核心成员一起在移动互联网行业从事了将近7年的游戏软件设计以及偏底层的系统开发工作。2016年，在医院后勤领域深耕了20年的泰科物业公司，希望将软件行业开发技术和泰科经验相结合，量身定制一套高效完整的后勤管理工具。因此，成立了广州医键通软件开发有限公司。</p>
                        
                        <p>医键通团队软件团队的初衷是希望为用户提供近乎极致的用户体验和技术服务。我们致力于解决应用统计查询效率低的问题，移动App方面，我们不断地提升软件的性能及减轻对网络的依赖，我们一直在为开拓前沿移动技术而不懈地努力。</p>
                        
                        <p>医键通的技术能力非常强，是业界少有的使用Go语言作为底层开发的公司之一；新技术的支持让我们的系统更加的稳定，我们仍然在深入地探索之中。</p>
                        
                        <p>医键通始终致力于提供高质量的客户服务，作为您的合作伙伴，我们将发扬孜孜不倦的工作精神，确保您的软件系统为您而设计，因您而设计。这是我们的使命，我们将携手共进。</p>
                    </div>
                    <div class="question-title">
                        <img :src=" questionIcon1" alt="">
                        <span>您可能感兴趣的内容</span>
                    </div>
                    <div class="question">
                        #1-如何选择软件公司
                    </div>
                    <div class="answer">
                        运行医键通需要配备电脑及移动设备，电脑系统win7以上、手机系统在安卓4.0以上，我们可以代企业配备，摒弃纳入售后服务中，服务器我们会使用阿里云服务器。针对某些特殊情况，我们建议采购本地服务器。这些在有需求时，将会被列入清单中由您自由选择。
                    </div>
                    <div class="question">
                        #2-医键通未来的定位是怎样的
                    </div>
                    <div class="answer">
                        医键通未来仍然会专注于医疗行业后勤管理系统的开发，对于其他的行业和领域也会有所涉及。我们将会继续坚持以技术为本的原则，注重底层技术的积累，打造一支更加强大、更加高效的开发团队。
                    </div>
                </div>
                <div v-if="changeContent == 2">
                    <div v-if="changeContent == 2" class="title">
                        <h2>常见问题</h2>
                    </div>
                     <div class="question-title">
                        <img :src=" questionIcon2" alt="" v-if="changeContent == 2">
                        <span v-if="changeContent == 2">常见问题</span>
                    </div>
                    <div class="question">
                        #1-软件配置要求
                    </div>
                    <div class="answer">
                        问：运行医键通系统需要什么软件配置？<br><br>
                        答：涉及到网页版、手机两个方面，网页版需要IE8.0以上浏览器，推荐使用谷歌浏览器进行访问。手机方面，安卓需要4.0系统、苹果IOS5以上即可运行。两者都需要稳定的网络访问。正常情况下，我们会为客户配置阿里云服务，如果涉及到特殊环境，我们建议搭建本地服务器。这些在有需求时，将会被列入清单中供您自由选择。
                    </div>
                    <div class="question">
                        #2-软件的使用权及收费
                    </div>
                    <div class="answer">
                        问：软件如何收费？<br>
                        答：可以按2种方式展开合作。第一种：使用泰科物业公司的后勤服务，软件将免费提供给企业使用。在合同期内免费二次开发及享受同等升级服务。第二种：按服务租用软件服务，可按功能模块制定符合企业的软件，按收取年费方式提供服务，在服务期内享受同等升级服务及二次开发服务。
                    </div>
                    <div class="question">
                        #3-是否支持第三方接口
                    </div>
                    <div class="answer">
                        问：是否支持第三方接口对接及第三方二次开发。<br>
                        答：我们的软件操作便利、灵活，可根据实际情况进行调整，各功能可按需定制，对接接口可按实际需求进行调整及研发。暂不支持第三方二次开发，未来会开放二次开发工具用于接入第三方插件功能。
                    </div>
                </div>
                <div class="learnmore">
                    这里找不到您的答案？或者您想了解更多 <span><router-link to="/experience">请把您的想法告诉我们</router-link></span>
                </div>
            </div>
        </div>
        <div :class="{occupy1:changeContent == 1,occupy2:changeContent == 2}">
            <!-- 占位 -->
        </div>
        <says/>
        <foots/>
        <hotline/>
    </div>
</template>

<script>
import foots from '@/components/pc/foot'
import says from '@/components/pc/say'
import hotline from '@/components/pc/hotline.vue'
export default {
    components:{
        foots,
        says,
        hotline
    },
    data(){
        return{
            topImg:require('@/assets/img/pcImg/aboutImg/12.png'),
            leftnavIcon1:require('@/assets/img/pcImg/aboutImg/about-sidebar-helo.png'),
            leftnavIcon2:require('@/assets/img/pcImg/aboutImg/about-sidebar-icon-contact.png'),
            questionIcon1:require('@/assets/img/pcImg/aboutImg/about-icon-content.png'),
            questionIcon2:require('@/assets/img/pcImg/aboutImg/about-icon-question.png'),
            changeContent:1,
        }
    }
}
</script>

<style lang="less" scoped>
.about{
    .top{
        width: 100%;
        height: 460px;
        background-image: repeating-linear-gradient(to right , rgb(31, 104, 240),  rgb(0, 79, 224));
        img{
            width: 100%;
            height: 460px;
            opacity: 0.2;
            z-index: -5;
        }
    }
    .main{
        width: 1200px;
        // height: 1074px;
        // background-color: darkslategrey;
        position: absolute;
        top: 150px;
        left: 50%;
        margin-left: -600px;
        .leftnav{
            width: 287px;
            height: 552px;
            background: #FFFFFF;
            box-shadow: 0px 15px 43px rgba(0, 0, 0, 0.05);
            opacity: 1;
            border-radius: 6px;
            .leftnav-head{
                width: 287px;
                height: 70px;
                background: #FAFBFE;
                border-radius: 3px 3px 0px 0px;
                img{
                    margin: 15px 10px 15px 20px;
                    vertical-align:middle;
                }
                span{
                    font-size: 16px;
                    font-weight: bold;
                    color: #31384E;
                    opacity: 1;
                }
            }
            .leftnav-title{
                width: 217px;
                height: 40px;
                // background: #EFF6FF;
                opacity: 1;
                border-radius: 25px 0px 0px 25px;
                margin: 10px 0 0 30px;
                font-size: 14px;
                font-weight: bold;
                line-height: 40px;
                color: #31384E;
                -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
                padding-left: 40px;
                cursor:pointer;
            }
            .changecontent{
                color: #207BFA;
                background: #EFF6FF;
            }
            .leftnav-content1{
                font-size: 14px;
                font-weight: 400;
                color: #31384E;
                margin: 20px 36px 20px 51px;
                span{
                    font-size: 14px;
                    font-weight: bold;
                    color: #207BFA;
                    cursor:pointer;
                }
            }
            .leftnav-content2{
                width: 207px;
                height: 69px;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #207BFA;
                margin: 20px 29px 20px 51px;
            }
        }
        .content{
            width: 800px;
            position: absolute;
            top: 70px;
            left: 400px;
            // background-color: dimgray;
            .title{
                font-weight: bold;
                color: #FFFFFF;
                // background-color: dodgerblue;
                height: 240px;
                h2{
                    font-size: 28px;
                    margin-bottom: 50px;
                }
                p{
                    font-size: 18px;
                    margin-bottom: 25px;
                }

            }
            .profile{
                font-size: 14px;
                font-weight: 400;
                color: #697598;
                margin-bottom: 40px;
                p{
                    margin-top: 20px;
                    text-indent:2em;
                }
            }
            .question-title{
                // background-color: dodgerblue;
                margin-top: 28px;
                img{
                    vertical-align:middle;
                    margin: -8px 10px 0 0 ;
                }
                span{
                    font-size: 18px;
                    font-weight: bold;
                    color: #31384E;
                }
            }
            .question{
                width: 800px;
                height: 40px;
                background: #FAFBFE;
                border-radius: 10px;
                line-height: 40px;
                text-indent:2em;
                margin: 20px 0 20px 0 ;
                font-size: 14px;
                font-weight: bold;
                color: #31384E;
            }
            .answer{
                width: 760px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #697598;
                margin-left: 40px;
            }
            .learnmore{
                font-size: 14px;
                font-weight: bold;
                color: #31384E;
                margin: 60px 0 60px 40px;
                span{
                    color:#207BFA;
                    a {
                        text-decoration: none;
                        color:#207BFA;
                    }
                    
                }
            }
        }
        
    }
    .occupy1{
        width: 100%;
        height: 764px;
        // background-color: dodgerblue;
        z-index: -5;
    }
    .occupy2{
        width: 100%;
        height: 669px;
        // background-color: dodgerblue;
        z-index: -5;
    }
    /deep/ .foot{
    background-color: #fff;
}
}



</style>